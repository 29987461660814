import { useCallback, useEffect, useState } from 'react';
import { getCardSet } from '../../util/cards.util';
import { UserCardComponent } from '../card/user_card.component';

export const CardsSetComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [cardsSet, setCardsSet] = useState([]);
    const [offset, setOffset] = useState(0);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const newResults = await getCardSet({ offset });
        setCardsSet((prevResults) => [...prevResults, ...newResults]);
        setIsLoading(false);
    }, [offset]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const threshold = document.body.scrollHeight * 0.75;

            if (scrollPosition >= threshold && !isLoading) {
                // Ensure we only update offset if loading is not in progress
                setOffset((prevOffset) => prevOffset + 20);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading]);

    return (
        <>
            <div className="flex justify-center items-center min-h-screen sm:pt-20 sm:mt-0 mt-4">
                <div className="flex flex-wrap gap-6 justify-center">
                    {cardsSet.map((card, index) => (
                        <UserCardComponent key={index} card={card} />
                    ))}
                </div>
            </div>
        </>
    );
};
