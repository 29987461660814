import { backendurl } from './backend_url.js';

export async function getDataFromBackend(
    url,
    postData,
    method,
    showProcess = true
) {
    return new Promise(async (resolve, reject) => {
        try {
            // if(showProcess)
            let query = '';
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('sessionid', localStorage.getItem('SessionId'));
            let data = {
                method,
                headers,
                credentials: 'include', // Explicitly type credentials
                // mode: 'no-cors',
            };

            if (method === 'POST') {
                data = { ...data, body: JSON.stringify(postData) };
            } else if (method === 'GET') {
                query = new URLSearchParams(postData).toString();
            }

            const response = await fetch(`${backendurl}${url}?${query}`, data);

            if (response.status >= 400 && response.status < 600) {
                console.log(
                    `Error url: ${url} Response status: ${response.status}`
                );
                // stop loading
                reject();
                return response;
            } else {
                let data = await response.json();
                // stop loading
                resolve(data);
            }
        } catch (error) {
            console.log(`Error url: ${url} Error: ${error}`);
        }
    });
}
