import { CardComponent } from './card.component';

export const UserCardComponent = ({ index, card }) => {
  return (
    <div className="mx-4" key={index}>
      {!card.pseudo ? (
        <div className="py-2 mb-4 h-11"></div>
      ) : (
        <div
          className={`mb-4 px-4 bg-white text-lg text-gray-800 font-bold rounded shadow-md items-center font-mono`}
          id="username"
        >
          {card.pseudo.substr(0, 15)}
        </div>
      )}
      <CardComponent card={card} flipped={false} />
    </div>
  );
};
