export const PokemonComponent = ({ pokemon }) => {
  const getImageUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.png`;
  };

  return (
    <div className="w-72 mx-5">
      <div
        className={`relative p-5 min-h-[260px] w-full rounded-lg flex flex-col items-start layout-0 type-${pokemon.types[0]}`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <span className="absolute left-[-42px] top-[35%] text-[110px] leading-[100px] font-extrabold font-mono text-white opacity-70 z-2">
            #{pokemon.pokemonId}
          </span>
          <div
            className="absolute inset-0 bg-cover bg-no-repeat z-1"
            style={{ backgroundImage: 'var(--overlay-1)' }}
          ></div>
          <div
            className="absolute inset-0 bg-cover bg-no-repeat z-1"
            style={{ backgroundImage: 'var(--overlay-2)' }}
          ></div>
        </div>
        <div className="relative z-3 flex flex-col items-start pt-2.5 pl-5">
          <span className="capitalize text-white font-bold text-2xl leading-[24px] mb-2.5">
            {pokemon.name}
          </span>
          <div className="flex flex-row gap-2">
            {pokemon.types.map((type) => (
              <div
                key={type}
                className={`text-center w-20 p-1 type-${type} rounded-md font-bold text-md text-white`}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
        <div className="relative z-3 w-[180px] bottom-[-60px]">
          <img
            src={getImageUrl(pokemon.pokemonId, pokemon.isShiny)}
            alt={pokemon.name}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};
