import { keepPokemonUtil } from '../../util';
import { ButtonComponent } from '../global/button.component';
import { SummonAnimationComponent } from './summun_animation.component';

export const SummonResultComponent = ({ pokemonSummoned, pokemons }) => {
  const getColorStatus = (status) => {
    switch (status) {
      case 'common':
        return 'bg-slate-300';
      case ' subLegendary':
        return 'bg-blue-300';
      case ' legendary':
        return 'bg-purple-300';
      case ' fabulous':
        return 'bg-yellow-300';
      default:
        return 'bg-slate-300';
    }
  };

  const handleKeepClick = async (action, pokemonIdToReplace = null) => {
    let data = {};

    if (action === 'release') {
      data = {
        pokemonIdToKeep: pokemonSummoned.pokemonId,
        action: 'release',
      };
    } else {
      data = {
        pokemonIdToKeep: pokemonSummoned.pokemonId,
        pokemonIdToReplace,
      };
    }
    await keepPokemonUtil(data);
    window.location.reload();
  };

  const getImageUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.png`;
  };

  const getSpriteUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.png`;
  };

  const get3dUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-vii/ultra-sun-ultra-moon/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.png`;
  };

  const getIconUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-viii/icons/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.png`;
  };

  return (
    <div className="flex md:flex-row flex-col pb-24 md:pb-0 md:pt-24 main-w mx-auto">
      <SummonAnimationComponent
        status={pokemonSummoned.status}
        isShiny={pokemonSummoned.isShiny}
      />
      <div className="flex flex-col">
        <div className="flex flex-col bg-white rounded-2xl p-3">
          <img
            src={getImageUrl(
              pokemonSummoned.pokemonId,
              pokemonSummoned.isShiny
            )}
            alt="full"
            className="bg-gray-300 rounded-lg"
          />
          <div className="flex flex-row flex-wrap items-center space-x-4 bg-gray-300 rounded-lg mt-3">
            <img
              src={getSpriteUrl(
                pokemonSummoned.pokemonId,
                pokemonSummoned.isShiny
              )}
              alt="full"
            />
            <img
              src={get3dUrl(pokemonSummoned.pokemonId, pokemonSummoned.isShiny)}
              alt="full"
            />
            <img
              src={getIconUrl(
                pokemonSummoned.pokemonId,
                pokemonSummoned.isShiny
              )}
              alt="full"
            />
          </div>
        </div>
        {pokemons.length > 5 ? (
          <ButtonComponent
            onClick={() => handleKeepClick('release')}
            label="Relacher"
          />
        ) : (
          <ButtonComponent
            onClick={() => handleKeepClick('', pokemonSummoned.pokemonId)}
            label="Garder"
          />
        )}
      </div>
      <div className="h-full w-full bg-white p-4 rounded-2xl sm:w-2/4 mx-4">
        <div className="font-bold text-3xl">
          #{pokemonSummoned.pokemonId} {pokemonSummoned.name}
        </div>
        <div
          className={`text-center p-1 rounded-md font-bold text-md m-0.5 ${getColorStatus(
            pokemonSummoned.status
          )}`}
        >
          {pokemonSummoned.status}
        </div>
        <div>
          <span className="font-bold">Types</span>
          <div className="flex flex-row flex-wrap">
            {pokemonSummoned.types.map((type) => (
              <div
                key={type}
                className={`text-center w-20 p-1 type-${type} rounded-md font-bold text-md text-white m-0.5`}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
        <div className="sm:w-1/3">
          <span className="font-bold">Faiblesses</span>
          <div className="flex flex-row flex-wrap">
            {Object.keys(pokemonSummoned.weaknesses).map((type) => (
              <div key={`${type}-weakness`}>
                <div
                  className={`text-center w-20 p-1 type-${type} rounded-md font-bold text-md text-white m-0.5`}
                >
                  {type}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <span className="font-bold">Résistances</span>
          <div className="flex flex-row flex-wrap">
            {Object.keys(pokemonSummoned.resistances).map((type) => (
              <div key={`${type}-resistance`}>
                <div
                  key={type}
                  className={`text-center w-20 p-1 type-${type} rounded-md font-bold text-md text-white m-0.5`}
                >
                  {type}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-center items-center sm:mt-0 mt-10">
        {pokemons.map((pokemon) => (
          <div key={pokemon.pokemonId}>
            <SimplePokemonTeamComponent
              pokemon={pokemon}
              spriteUrl={getSpriteUrl(pokemon.pokemonId, pokemon.isShiny)}
              handleKeepClick={handleKeepClick}
              isTeamFull={pokemons.length > 5}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const SimplePokemonTeamComponent = ({
  pokemon,
  spriteUrl,
  handleKeepClick,
  isTeamFull = true,
}) => {
  return (
    <div className="flex flex-col">
      <div className="w-[150px] h-[150px] m-4 relative">
        <div
          className={`relative p-2 w-full h-full rounded-lg flex flex-col items-start layout-0 type-${pokemon.types[0]}`}
        >
          <div className="absolute inset-0 overflow-hidden">
            <span className="absolute left-[-20px] top-[40%] text-[64px] leading-[64px] font-extrabold font-mono text-white opacity-70 z-2">
              #{pokemon.pokemonId}
            </span>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat z-1"
              style={{ backgroundImage: 'var(--overlay-1)' }}
            ></div>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat z-1"
              style={{ backgroundImage: 'var(--overlay-2)' }}
            ></div>
          </div>
          <div className="relative z-3 flex flex-col items-start ">
            <span className="capitalize text-white font-bold text-sm leading-[16px]">
              {pokemon.name}
            </span>
            <div className="flex flex-row gap-2">
              {pokemon.types.map((type) => (
                <div
                  key={type}
                  className={`text-center px-2 type-${type} rounded-md font-bold text-sm text-white `}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
          <div className="relative z-3 bottom-[-40px]">
            <img src={spriteUrl} alt={pokemon.name} />
          </div>
        </div>
      </div>
      {isTeamFull ? (
        <ButtonComponent
          onClick={() => handleKeepClick('', pokemon.pokemonId)}
          label="Relacher"
        />
      ) : (
        ''
      )}
    </div>
  );
};
