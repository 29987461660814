import { ShinyStarComponent } from '../index';

export const SmallPokemonComponent = ({ pokemon }) => {
  const getImageUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/showdown/';
    //   'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-viii/icons/';
    // 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.gif`;
  };

  return (
    <div>
      <div
        className={`${
          pokemon.isShiny ? 'shiny-border' : ''
        } w-[150px] h-[150px] m-4 relative`}
      >
        <div
          className={`relative p-2 w-full h-full rounded-lg flex flex-col items-start layout-0 type-${pokemon.types[0]}`}
        >
          <div className="absolute inset-0 overflow-hidden">
            <span className="absolute left-[-20px] top-[40%] text-[64px] leading-[64px] font-extrabold font-mono text-white opacity-70 z-2">
              #{pokemon.pokemonId}
            </span>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat z-1"
              style={{ backgroundImage: 'var(--overlay-1)' }}
            ></div>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat z-1"
              style={{ backgroundImage: 'var(--overlay-2)' }}
            ></div>
          </div>
          <div className="relative z-3 flex flex-col items-start ">
            <span className="flex flex-row items-center capitalize text-white font-bold text-l mb-2">
              <ShinyStarComponent isShiny={pokemon.isShiny} />{' '}
              <div className="mx-2">{pokemon.name} </div>
            </span>
            <div className="flex flex-row gap-2">
              {pokemon.types.map((type) => (
                <div
                  key={type}
                  className={`text-center px-2 type-${type} rounded-md font-bold text-sm text-white `}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
          <div className="relative z-3 bottom-[-40px]">
            <img
              src={getImageUrl(pokemon.pokemonId, pokemon.isShiny)}
              alt={pokemon.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
