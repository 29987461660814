import { ShinyStarComponent } from './shiny_star.component';

export const SmallPokemonMobileComponent = ({ pokemon }) => {
  const getImageUrl = (pokemonId, isShiny = false) => {
    const baseUrl =
      'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/showdown/';
    //   'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-viii/icons/';
    // 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/';
    return `${baseUrl}${isShiny ? 'shiny/' : ''}${pokemonId}.gif`;
  };

  return (
    <div className="bg-gradient-to-b from-blue-300 to-blue-400 py-2 rounded-md">
      <div className="flex flex-col justify-center items-center">
        <div className="h-24 flex items-center">
          <img
            src={getImageUrl(pokemon.pokemonId, pokemon.isShiny)}
            alt={pokemon.name}
            className="max-h-24"
          />
        </div>
        <span className="capitalize my-1 flex justify-center">
          <div className="mx-2">
            #{pokemon.pokemonId} {pokemon.name}{' '}
          </div>
          <ShinyStarComponent isShiny={pokemon.isShiny} />
        </span>
        <div className="flex flex-row my-1 gap-2 justify-center">
          {pokemon.types.map((type) => (
            <div
              key={type}
              className={`text-center px-2 type-${type} rounded-md font-bold text-sm`}
            >
              {type}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
