import { discordRedirect } from '../../util/';

export const ConnectionComponent = ({ set }) => {
    // const [token, setToken] = useState('');

    const handleSignInClick = async () => {
        await discordRedirect();
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
                <div className="flex justify-between items-center mb-4">
                    <button
                        type="button"
                        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        onClick={handleSignInClick}
                    >
                        Connexion avec Discord
                    </button>
                </div>
            </div>
        </div>
    );
};
