import { getDataFromBackend } from './';

const summonPokemonUtil = async () => {
  localStorage.setItem('lastTimeSummon', Math.floor(Date.now() / 1000));
  const response = await getDataFromBackend(
    '/pokemons/summon',
    {},
    'POST',
    true
  );
  return await response;
};

const keepPokemonUtil = async (data) => {
  const response = await getDataFromBackend(
    '/users/pokemons/pending/action',
    data,
    'POST',
    true
  );
  return await response;
};

const getPokemonUtil = async () => {
  const response = await getDataFromBackend('/users/pokemons', {}, 'GET', true);
  return await response;
};

const getPokemonLadderUtil = async () => {
  const response = await getDataFromBackend(
    '/pokemons/ladder',
    {},
    'GET',
    true
  );
  return await response;
};

export {
  getPokemonLadderUtil,
  getPokemonUtil,
  keepPokemonUtil,
  summonPokemonUtil,
};
