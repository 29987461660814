import React, { useEffect, useRef, useState } from 'react';
import { LoadingComponent } from '../global/loading.component';

export const SummonAnimationComponent = ({ status, isShiny }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [source, setSource] = useState('/video/summon_common.mp4');
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    switch (`${isShiny ? 'shiny' : ''} ${status}`) {
      case ' fabulous':
      case ' legendary':
        setSource('/video/summon_high.mp4');
        break;
      case ' subLegendary':
        setSource('/video/summon_mid.mp4');
        break;
      case 'shiny fabulous':
      case 'shiny legendary':
        setSource('/video/summon_high_shiny.mp4');
        break;
      case 'shiny subLegendary':
        setSource('/video/summon_mid_shiny.mp4');
        break;
      case 'shiny common':
        setSource('/video/summon_common_shiny.mp4');
        break;
      default:
        break;
    }
    setIsLoading(false);

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [status, isShiny]);

  const handleVideoEnded = () => {
    setIsVisible(false);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 z-[100] overflow-hidden bg-black">
          <video
            ref={videoRef}
            autoPlay
            muted
            onEnded={handleVideoEnded}
            className="absolute inset-0 h-full w-full object-cover"
          >
            <source src={source} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
};
