import { useCallback, useEffect, useState } from 'react';
import { CardComponent } from '..';
import { getCardsOwnUtil } from '../../util';

export const CardOwnedComponent = () => {
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const newResults = await getCardsOwnUtil(offset);
        setResults((prevResults) => [...prevResults, ...newResults]);
        setIsLoading(false);
    }, [offset]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const threshold = document.body.scrollHeight * 0.75;

            if (scrollPosition >= threshold && !isLoading) {
                // Ensure we only update offset if loading is not in progress
                setOffset((prevOffset) => prevOffset + 20);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading]);

    return (
        <>
            <div className="mx-auto min-h-screen main-w sm:py-20 pb-28 pt-3">
                <div className="flex flex-row gap-y-20 flex-wrap justify-around items-center py-2 md:py-14 rounded-2xl ">
                    {results.map((card, index) => (
                        <div key={index}>
                            <CardComponent card={card} flipped={false} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
