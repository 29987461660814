import { useState } from 'react';
import { drawCardUtil, summonPokemonUtil } from '../../util/index';
import {
    CardsPullResultComponent,
    LoadingComponent,
    SummonResultComponent,
} from '../index';
import { CarouselComponent } from './carousel.component';

export const MainComponent = ({ pokemons, setHeader }) => {
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pokemonSummoned, setPokemonSummoned] = useState({});
    const [cardPulled, setCardPulled] = useState({});

    const handlePullClick = async () => {
        setResult('pull');
        setIsLoading(true);
        setCardPulled(await drawCardUtil());
        setIsLoading(false);
    };

    const handleSummonClick = async () => {
        setResult('summon');
        setIsLoading(true);
        setPokemonSummoned(await summonPokemonUtil());
        setIsLoading(false);
    };

    if (result === '') {
        return (
            <CarouselComponent
                handlePullClick={handlePullClick}
                handleSummonClick={handleSummonClick}
                setHeader={setHeader}
            />
        );
    }

    if (result === 'pull') {
        if (isLoading) {
            return <LoadingComponent />;
        }
        return <CardsPullResultComponent results={cardPulled} />;
    }

    if (result === 'summon') {
        if (isLoading) {
            return <LoadingComponent />;
        }
        return (
            <SummonResultComponent
                pokemonSummoned={pokemonSummoned}
                pokemons={pokemons}
            />
        );
    }
};
