import { isMobile } from 'react-device-detect';
import { SmallPokemonMobileComponent } from '../pokemon/small_pokemon.mobile.component';
import { PokemonComponent } from './pokemon.component';

export const PokemonTeamComponent = ({ pokemons }) => {
  if (isMobile) {
    return (
      <div className="mx-auto main-w sm:py-20 pb-28 pt-3">
        <div className=" p-4 bg-gradient-to-b from-blue-100 to-blue-200 rounded-xl">
          <div className="grid grid-cols-2 gap-2">
            {pokemons.map((pokemon) => (
              <SmallPokemonMobileComponent pokemon={pokemon} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto min-h-screen main-w sm:py-20 pb-28 pt-3">
      <div className="flex flex-row gap-y-20 flex-wrap justify-around items-center py-2 md:py-14 rounded-2xl">
        <div className="grid grid-cols-3">
          {pokemons.map((pokemon) => (
            <div className="grid grid-cols-3">
              <PokemonComponent pokemon={pokemon} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
