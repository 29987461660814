import { CardComponent } from '../';

export const CardsPullResultComponent = ({ results }) => {
    const formatResults = (cards) => {
        cards = cards.sort(() => Math.random() - 0.5);
        return cards.map((card, index) => (
            <div className="m-12 " key={index}>
                <CardComponent card={card} />
            </div>
        ));
    };

    return (
        <>
            <div className="flex justify-center items-center min-h-screen p-4">
                <div className="flex flex-wrap gap-6 justify-center">
                    {formatResults(results)}
                </div>
            </div>
        </>
    );
};
