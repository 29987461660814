import React, { useCallback, useEffect, useState } from 'react';

const TimerComponent = ({ endTimestamp }) => {
  const [categoryClasses, setCategoryClasses] = useState({});

  const calculateTimeLeft = useCallback(() => {
    const difference = endTimestamp - Math.floor(Date.now() / 1000);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        H: Math.floor((difference / (60 * 60)) % 24),
        M: Math.floor((difference / 60) % 60),
        S: Math.floor(difference % 60),
      };
    }

    return timeLeft;
  }, [endTimestamp]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);

      if (Object.keys(timeLeft).length === 0) {
        clearInterval(timer);
        window.location.reload(); // Refresh the page when time is up
      }
    }, 1000);

    setCategoryClasses({
      H: `category-${Math.floor(Math.random() * 19) + 1}`,
      M: `category-${Math.floor(Math.random() * 19) + 1}`,
      S: `category-${Math.floor(Math.random() * 19) + 1}`,
    });

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const padWithZero = (number) => {
    return number.toString().padStart(2, '0');
  };

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <div
      key={interval}
      className={`flex flex-col items-center p-2 sm:p-4 m-2 sm:m-4 w-16 h-16 tn:w-20 tn:h-20 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-lg justify-center ${categoryClasses[interval]}`}
    >
      <div className="overlay relative w-full h-full flex items-center justify-center">
        <span className="text-4xl sm:text-6xl md:text-8xl font-bold">
          {padWithZero(timeLeft[interval])}
        </span>
      </div>
    </div>
  ));

  return (
    <div id="clock" className="mt-4 flex items-center justify-center ">
      <div className="flex flex-wrap justify-center">
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>
    </div>
  );
};

export { TimerComponent };
