import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getPokemonLadderUtil } from '../../util/pokemon.util';
import { LoadingComponent } from '../global/loading.component';
import { SmallPokemonComponent } from '../pokemon/small_pokemon.component';
import { SmallPokemonMobileComponent } from '../pokemon/small_pokemon.mobile.component';

const PokemonLadderComponent = () => {
  const [ladder, setLadder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadInformation = async () => {
      setLadder(await getPokemonLadderUtil());
      setIsLoading(false);
    };
    loadInformation();
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isMobile) {
    return (
      <div className="mx-auto main-w sm:py-20 pb-28 pt-3">
        <div className="flex flex-col gap-y-10 flex-wrap justify-around items-center md:py-14">
          {Object.keys(ladder).map((user) => {
            return (
              <div className="w-full rounded-xl p-4 bg-gradient-to-b from-blue-100 to-blue-200 ">
                <div
                  className={`mb-4 px-4 w-fit bg-white text-lg text-gray-800 font-bold rounded shadow-md font-mono`}
                  id="username"
                >
                  {user}
                </div>
                <div className="">
                  <div className="grid grid-cols-2 gap-2">
                    {ladder[user].map((pokemon) => (
                      <SmallPokemonMobileComponent pokemon={pokemon} />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto main-w sm:py-20 pb-28 pt-3">
        <div className="flex flex-col gap-y-20 flex-wrap justify-around items-center py-2 md:py-14">
          {Object.keys(ladder).map((user) => {
            return (
              <div className="w-full rounded-xl p-4 bg-gradient-to-b from-blue-100 to-blue-200 ">
                <div
                  className={`mb-4 px-4 w-fit bg-white text-lg text-gray-800 font-bold rounded shadow-md font-mono`}
                  id="username"
                >
                  {user}
                </div>
                <div className="flex justify-center">
                  <div className="grid grid-cols-3">
                    {ladder[user].map((pokemon) => (
                      <SmallPokemonComponent pokemon={pokemon} />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { PokemonLadderComponent };
