import { isMobile, isTablet } from 'react-device-detect';
import { HeaderMobileComponent } from './header.mobile.component';

export const HeaderComponent = ({ setHeader }) => {
  if (isMobile || isTablet) {
    return <HeaderMobileComponent setHeader={setHeader} />;
  }

  return (
    <header className="z-50 fixed w-full">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div className="flex items-center">
            <img
              src="/img/icon.webp"
              className="mr-3 h-6 sm:h-9"
              alt="Ranni Logo"
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Ranni Quest
            </span>
          </div>
          <div
            className={`hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <div
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white hover:cursor-pointer"
                  onClick={() => window.location.reload()}
                >
                  Accueil
                </div>
              </li>
              <li>
                <div
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white hover:cursor-pointer"
                  onClick={() => setHeader('ladders')}
                >
                  Ladders
                </div>
              </li>
              <li>
                <div
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white hover:cursor-pointer"
                  onClick={() => setHeader('pokemon_teams')}
                >
                  Équipes
                </div>
              </li>
              <li>
                <div
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white hover:cursor-pointer"
                  onClick={() => setHeader('my_cards')}
                >
                  Mes cartes
                </div>
              </li>
              <li>
                <div
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white hover:cursor-pointer"
                  onClick={() => setHeader('my_pokemon')}
                >
                  Mes Pokémons
                </div>
              </li>
              {/* <li>
                                <div className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                                    Dev Logs ???
                                </div>
                            </li> */}
              {/* <li>
                                <a
                                    href="#"
                                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                >
                                    Team
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                >
                                    Contact
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
