import { useState } from 'react';
import { isMobile } from 'react-device-detect';

export function CardComponent({ card, flipped = true }) {
  const [isFlipped, setIsFlipped] = useState(isMobile ? false : flipped);
  const [isInteracting, setIsInteracting] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [pointerFromCenter, setPointerFromCenter] = useState('0');
  const [pointerFromLeft, setPointerFromLeft] = useState(0);
  const [pointerFromTop, setPointerFromTop] = useState(0);

  const [pointerX, setPointerX] = useState('50%');
  const [pointerY, setPointerY] = useState('50%');
  const [backgroundX, setBackgroundX] = useState('0%');
  const [backgroundY, setBackgroundY] = useState('0%');
  const [rotateX, setRotateX] = useState('0deg');
  const [rotateY, setRotateY] = useState('0deg');

  const onMouseEnter = () => {
    if (isFlipped && !isMobile) {
      setIsFlipped(false);
    }
    setIsInteracting(true);
  };

  const onMouseMove = (e) => {
    let el = e.target;
    let card = el.getBoundingClientRect();
    let w = el.clientWidth;
    let h = el.clientHeight;
    let cursorX = (e.clientX - card.left) / w;
    let cursorY = (e.clientY - card.top) / h;

    let backgroundX = 40 + 20 * cursorX;
    let backgroundY = 40 + 20 * cursorY;
    let rotateX = -(cursorX - 0.5) * 26;
    let rotateY = (cursorY - 0.5) * 26;
    let pointerFromCenter =
      Math.sqrt(
        (cursorY - 50) * (cursorY - 50) + (cursorX - 50) * (cursorX - 50)
      ) / 50;
    let pointerFromLeft = cursorX / 100;
    let pointerFromTop = cursorY / 100;

    setPointerX(cursorX * 100 + '%');
    setPointerY(cursorY * 100 + '%');
    setBackgroundX(backgroundX + '%');
    setBackgroundY(backgroundY + '%');
    setRotateX(rotateX + 'deg');
    setRotateY(rotateY + 'deg');
    setPointerFromCenter(pointerFromCenter);
    setPointerFromLeft(pointerFromLeft);
    setPointerFromTop(pointerFromTop);
  };

  const onMouseLeave = () => {
    setTimeout(() => {
      setPointerX(50 + '%');
      setPointerY(50 + '%');
      setBackgroundX(backgroundX + '%');
      setBackgroundY(backgroundY + '%');
      setRotateX(0 + 'deg');
      setRotateY(0 + 'deg');
      setPointerFromCenter(0);
      setPointerFromLeft(0.5);
      setPointerFromTop(0.5);
      setIsInteracting(false);
    }, 3000);
  };

  const handleCardClick = () => {
    if (!isMobile) {
      setIsClicked(!isClicked);
    }
  };

  return (
    <div
      className={`card mx-4 ${
        isClicked ? 'fixed inset-0 flex items-center justify-center z-50' : ''
      } ${isInteracting ? 'interacting' : ''}`}
      style={
        isInteracting
          ? {
              '--pointer-x': pointerX,
              '--pointer-y': pointerY,
              '--rotate-x': rotateX,
              '--rotate-y': rotateY,
              '--background-x': backgroundX,
              '--background-y': backgroundY,
              '--pointer-from-left': pointerFromLeft,
              '--pointer-from-top': pointerFromTop,
              '--pointer-from-center': pointerFromCenter,
            }
          : {}
      }
      data-rarity={card.rarityEffect}
      data-effect={card.effect}
      data-series={card.series}
      data-supertype={card.supertype}
      data-subtypes={card.subtype}
    >
      <div
        className={`card__rotator relative rounded-xl ${
          isFlipped ? 'card__flip__rotate' : ''
        }  `}
        style={{
          width: isClicked ? '' : 300,
          transform: isClicked ? 'scale(0.8)' : '',
          // transition:
          //     'transform 0.5s ease, width 0.5s ease, height 0.5s ease',
        }}
        onClick={handleCardClick}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <div className="card__front__rotate pointer-events-none">
          <div className="card__image pointer-events-none">
            <img
              className="pointer-events-none"
              src={card.largeImageUrl}
              alt="front"
            />
          </div>
          <div className="card__shine absolute rounded-xl top-0 left-0 w-full h-full pointer-events-none"></div>
          <div className="card__glare absolute rounded-xl top-0 left-0 w-full h-full pointer-events-none"></div>
          <div className="card_rarity_shine absolute rounded-xl top-0 left-0 w-full h-full pointer-events-none"></div>
        </div>

        <div className="card__back__rotate absolute top-0 left-0 w-full h-full">
          <div className="card__back__image">
            <img src="./img/backside.png" alt="back" />
          </div>
          <div className="card__back__shine rounded-xl"></div>
        </div>
      </div>
    </div>
  );
}
