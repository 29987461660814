import { getDataFromBackend } from './';

export const drawCardUtil = async () => {
    const response = await getDataFromBackend('/cards/pull', {}, 'POST', true);
    return await response;
};

export const getCardsOwnUtil = async (offset = 0) => {
    const response = await getDataFromBackend(
        '/users/cards',
        { offset },
        'GET',
        true
    );
    return await response;
};

export const getLadder = async () => {
    const response = await getDataFromBackend(
        '/cards/pull/latest',
        {},
        'GET',
        true
    );
    return await response;
};

export const getCardSet = async (data) => {
    const response = await getDataFromBackend('/cards/set', data, 'GET', true);
    return await response;
};
