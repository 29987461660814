import { useEffect, useState } from 'react';
import './App.css';
import {
  CardLadderComponent,
  CardOwnedComponent,
  ConnectionComponent,
  HeaderComponent,
  LoadingComponent,
  MainComponent,
} from './components';
import { CardsSetComponent } from './components/home/cards_set.component';
import { PokemonLadderComponent } from './components/ladder/pokemon_ladder.component';
import { PokemonTeamComponent } from './components/my_pokemons/pokemon_team.component';
import { getPokemonUtil, loginToBackend } from './util';

function App() {
  const [pokemons, setPokemons] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [header, setHeader] = useState('main');

  useEffect(() => {
    const loadInformation = async () => {
      const response = await loginToBackend();
      setIsConnected(response);
      setIsLoading(false);

      if (response) {
        setPokemons(await getPokemonUtil());
      }
    };
    loadInformation();
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!isConnected) {
    return <ConnectionComponent />;
  }

  if (header === 'main')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <MainComponent pokemons={pokemons} setHeader={setHeader} />
      </>
    );
  if (header === 'my_cards')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <CardOwnedComponent />
      </>
    );
  if (header === 'my_pokemon')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <PokemonTeamComponent pokemons={pokemons} />
      </>
    );
  if (header === 'ladders')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <CardLadderComponent />
      </>
    );
  if (header === 'cards_set')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <CardsSetComponent />
      </>
    );
  if (header === 'pokemon_teams')
    return (
      <>
        <HeaderComponent setHeader={setHeader} />
        <PokemonLadderComponent />
      </>
    );
}

export default App;
