import { ButtonComponent } from '../global/button.component';
import { TimerComponent } from '../global/timer.component';

export const CardsPullButtonComponent = ({ onClick, setHeader }) => {
  const pullTimer = parseInt(localStorage.getItem('pullTimer'));
  const lastTimePull = parseInt(localStorage.getItem('lastTimePull'), 10);
  const isTooSoonToPull =
    pullTimer >= Math.floor(Date.now() / 1000) - lastTimePull;

  return (
    <div className="main-w mx-auto flex flex-col items-center justify-center">
      <img
        className="max-w-[1240px] w-full h-auto mx-auto cursor-pointer"
        src={`/img/banner/${localStorage.getItem('setId')}.jpg`}
        alt="summon"
        onClick={() => setHeader('cards_set')}
      />
      {isTooSoonToPull ? (
        <TimerComponent endTimestamp={lastTimePull + pullTimer} />
      ) : (
        <ButtonComponent onClick={onClick} label="Ouverture Booster" />
      )}
    </div>
  );
};
