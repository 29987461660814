import { useState } from 'react';
import { CardsPullButtonComponent } from '../my_cards/cards_pull_button.component';
import { SummonPokemonButtonComponent } from '../my_pokemons/summon_pokemon_button.component';

export const CarouselComponent = ({
  handlePullClick,
  handleSummonClick,
  setHeader,
}) => {
  const [current, setCurrent] = useState(0);

  const tabs = [
    {
      id: 'tabs-home',
      icon: (
        <img
          src={`/img/icon/${localStorage.getItem('setId')}.png`}
          alt="Booster"
          className="w-24"
        />
      ),
      content: (
        <CardsPullButtonComponent
          onClick={handlePullClick}
          setHeader={setHeader}
        />
      ),
    },
    {
      id: 'tabs-profile',
      icon: (
        <img src="/img/icon/pokemon.png" alt="Booster" className="w-16 -mt-6" />
      ),
      content: <SummonPokemonButtonComponent onClick={handleSummonClick} />,
    },
  ];

  return (
    <div className="mx-auto sm:pt-24 sm:mt-0 mt-12 main-w ">
      <div className="mb-5 flex flex-row mx-auto justify-center">
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={`mx-2 rounded-lg px-7 text-xs font-medium uppercase cursor-pointer h-10 flex items-center justify-center ${
              current === index ? 'bg-yellow-100' : 'bg-white'
            } `}
            aria-selected={current === index}
            onClick={() => setCurrent(index)}
          >
            {tab.icon}
          </div>
        ))}
      </div>

      <div>
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={`hidden ${
              current === index ? 'data-[twe-tab-active]:block' : ''
            }`}
            data-twe-tab-active={current === index}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};
