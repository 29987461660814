import { ButtonComponent } from '../global/button.component';
import { TimerComponent } from '../global/timer.component';

export const SummonPokemonButtonComponent = ({ onClick }) => {
  const summonTimer = parseInt(localStorage.getItem('summonTimer'));
  const lastTimeSummon = parseInt(localStorage.getItem('lastTimeSummon'), 10);
  const isTooSoonToSummon =
    summonTimer >= Math.floor(Date.now() / 1000) - lastTimeSummon;

  return (
    <div className="main-w mr-0 flex flex-col">
      <img
        src={`/img/banner/banner-${Math.floor(Math.random() * 4) + 1}.gif`}
        alt="summon"
        className="max-w-[1240px] w-full h-auto mx-auto"
      />
      {isTooSoonToSummon ? (
        <TimerComponent endTimestamp={lastTimeSummon + summonTimer} />
      ) : (
        <ButtonComponent onClick={onClick} label="Invocation" />
      )}
    </div>
  );
};
