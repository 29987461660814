import { useEffect, useState } from 'react';
import { LoadingComponent } from '..';
import { getLadder } from '../../util';
import { UserCardComponent } from '../card/user_card.component';

const CardLadderComponent = () => {
  const [ladder, setLadder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadInformation = async () => {
      setLadder(await getLadder());
      setIsLoading(false);
    };
    loadInformation();
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="mx-auto min-h-screen main-w sm:py-20 pb-28 pt-3">
        <div className="flex flex-row gap-y-20 flex-wrap justify-around items-center py-2 md:py-14 rounded-2xl">
          {ladder.map((card, index) => (
            <UserCardComponent key={index} card={card} />
          ))}
        </div>
      </div>
    </>
  );
};
export { CardLadderComponent };
