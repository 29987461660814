import { getDataFromBackend } from './';

export const loginToBackend = async () => {
  try {
    const response = await getDataFromBackend('/login', {}, 'POST', true);

    if (!response?.discordId && !response?.pseudo) {
      localStorage.clear();
      return false;
    }

    localStorage.setItem('pseudo', await response?.pseudo);
    localStorage.setItem('lastTimePull', await response?.lastTimePull);
    localStorage.setItem('lastTimeSummon', await response?.lastTimeSummon);
    localStorage.setItem('setUrl', response?.setting?.setUrl);
    localStorage.setItem('pullTimer', response?.setting?.pullTimer);
    localStorage.setItem('summonTimer', response?.setting?.summonTimer);
    localStorage.setItem('setId', response?.setting?.setId);
  } catch {
    return false;
  }

  return true;
};

export const discordRedirect = async () => {
  const response = await getDataFromBackend(
    '/discord/redirect',
    {},
    'GET',
    true
  );
  window.location.replace(response.url);
};
