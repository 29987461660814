export const ButtonComponent = ({ onClick, label }) => {
  return (
    <div className="mx-auto mt-4 border-2 border-white rounded-full w-fit">
      <button
        className="py-2 px-6 bg-white rounded-full font-space-mono font-extrabold border-2 border-green-500"
        onClick={onClick}
      >
        <span>{label}</span>
      </button>
    </div>
  );
};
